<template>
  <div class="fybody">
    <!--============    左侧大列表    ============================== -->
    <div class="fybody_left">
      <!--    选择声音-->
      <div class="fy_first"  :style="backgroundImage">
        <div class="fy_first_bg" :style="currentBackground?'backdrop-filter: blur(44px);':'none'"></div>
        <div class="first_left">
          <div class="first_left_one" @click="dialogVoice = true">
            <i class="el-icon-plus icon-bold" ></i>
          </div>
          <div class="first_left_two" v-if="Object.keys(selectVoice).length > 0">
            <div class="first_left_two_up">{{selectVoice.artist}}</div>
            <div class="first_left_two_down">{{selectVoice.genre}}</div>
          </div>
          <div class="first_left_two" v-else>
            <div class="first_left_two_up">Select a voice</div>
            <div class="first_left_two_down">Browse hundreds of royalty free  voices</div>
          </div>
        </div>
        <div class="first_right" v-if="Object.keys(selectVoice).length > 0">
          <img class="right_img_c" :src="selectVoice.thumbnail" alt="" />
        </div>
        <div class="first_right" v-else>
          <img class="right_img" src="@/assets/images/ai/select_artist.png" alt="" />
        </div>
      </div>
      <!--    上传音频-->
      <div class="fy_second">
        <div class="sec_lef">
            <div class="sec_lef_up">
              <div class="sec_lef_up_le">
                <div class="sec_lef_up_le_da">Upload Audio</div>
                <div class="sec_lef_up_le_xiao">Or Drag And Drop Mp3/Wav File Here</div>
              </div>
              <div class="sec_lef_up_ri">
                <i class="el-icon-upload2" style="font-size: 50px"></i>
              </div>
            </div>
            <div class="sec_lef_down">
              <el-upload class="" :file-list="fileList" :action="uploadUrl" name="file" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess"  :on-remove="handleRemove" :limit="1">
                <div v-if="root_path.length == 0" class="sec_lef_down_box">
                  <i class="el-icon-plus" style="font-size: 30px"></i>
                </div>
              </el-upload>
            </div>
        </div>
        <!--<div class="sec_lef"></div>-->
      </div>
      <!--    选择移除的东西-->
      <!--<div class="fy_third">
        <div class="third_le" @click="instrumentalsFunc" :class="{third_le_selected: instrumentals == 1}">
          <div class="third_le_nei"><i class="el-icon-orange" style="margin-right: 10px"></i>Remove Instrumentals</div>
        </div>
        <div class="third_le third_ri" @click="reberbFunc" :class="{third_le_selected: reverb == 1}">
          <div class="third_le_nei"><i class="el-icon-mic" style="margin-right: 10px"></i>Remove Reverb/Echo</div>
        </div>
      </div>-->
      <!--    滑动调整参数-->
      <!--<div class="fy_fourth">
        <div class="fourth_up">
          <div class="fourth_up_left">
            <div class="fourth_up_left_one">Set Pitch</div>
            <div class="fourth_up_left_two">{{pitch_val_fu}}</div>
          </div>
          <div class="fourth_up_right">
            <el-slider style="width: 80%" v-model="pitch_val" :min="-12" :step="1" :max="12" :format-tooltip="formatTooltip"></el-slider>
            <div class="fourth_up_right_one">{{pitch_val_zheng}}</div>
          </div>
        </div>
        <div class="fourth_up">
          <div class="fourth_up_left">Formant Shift</div>
          <div class="fourth_up_right">
            <el-slider style="width: 80%" v-model="formant_val" :min="0.1" :step="0.1" :max="2"></el-slider>
            <div class="fourth_up_right_one">{{formant_val}}</div>
          </div>
        </div>
      </div>-->
      <!--      我的积分详情-->
      <div class="wode">
        <span class="wodd bbttnn">{{$t('musicaiwodejifen')}}：{{myintegral}}</span>
        <el-divider class="wodd" direction="vertical"></el-divider>
        <span class="wodd bbttnn">{{$t('musicaixiaohaojifen')}}</span>
      </div>
      <!--    按钮-->
      <div class="fy_fiveth">
        <el-button v-if="button_unable" :loading="button_unable" class="gradient-btn">正在生成中</el-button>
        <el-button v-else   @click="createMusicfy()" class="gradient-btn">立即生成</el-button>
      </div>
    </div>

    <!--============    右侧大列表    ==============================-->
    <div class="fybody_right">
      <el-scrollbar class="right_list"  wrap-style="overflow-x:hidden;">
        <div class="right_list_m" v-for="(item,index) in tableData" :key="index">
          <div class="m_title" v-if="item.artist">{{item.artist}}</div>
          <div class="m_box">
            <musicfy-list-icon  :obj="item" :pause_action="pause_action"></musicfy-list-icon>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <!--============    弹窗 选择声音    ========================-->
    <el-dialog top="1vh" title="" :visible.sync="dialogVoice" width="70%" center @open="handleOpen" @close="handleClose">
      <div class="voictype">
        <div class="voictype_one" :class="{'selected': selectedItem === 1}"  @click="selectItem(1,'parody')">Parody</div>
        <div class="voictype_one" :class="{'selected': selectedItem === 2}"  @click="selectItem(2,'royalty_free')">Royalty Free</div>
        <div class="voictype_one" :class="{'selected': selectedItem === 3}"  @click="selectItem(3,'instrumentals')">Instrumentals</div>
      </div>
      <div class="dialog_voice">
        <div class="dialog_voice_box" :class="{'choose_vi_css':item.ids == choose_v_item.ids}" v-for="(item,index) in voiceData" :key="index">
          <img @click="chooseVoice(item)" :src="item.thumbnail ? item.thumbnail : 'https://tiji2021.oss-cn-beijing.aliyuncs.com/musicfy/system/7KEYMUSIC_LOGO.jpg'" alt="" >
          <div class="dialog_info">
            <div class="dialog_info_left" v-show="item.audioPreviewUrl">
              <musicfy-icon :obj="item" :pause_action="pause_action"></musicfy-icon>
            </div>
            <div class="dialog_info_right">
              <div class="dialog_info_right_up"><span>{{item.artist}}</span></div>
              <div class="dialog_info_right_down">{{item.genre}}</div>
            </div>
          </div>
        </div>
      </div>
      <!--      分页    -->
      <div class="pagecss">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size = "limit"
            layout="total, prev, pager, next"
            :total="voiceTotal">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVoice = false">取 消</el-button>
        <el-button type="primary" @click="chooseClosedia()">确 定</el-button>
      </span>
    </el-dialog>

  </div>

</template>

<script>
import MusicfyIcon from "@/components/content/MusicfyIcon";
import MusicfyListIcon from "@/components/content/MusicfyListIcon";
import {
  musicfyGetVoice, musicfyCreate, musicfyGetList,
} from "@/api/Mine";
import MusicAiIcon from "@/components/content/MusicAIIcon";
export default {
  name: "Musicfy",
  components: {
    MusicfyIcon,MusicfyListIcon,
  },
  data() {
    return {
      currentBackground:'',
      bgImageUrl:'',
      pitch_val: 0,
      pitch_val_fu:0,
      pitch_val_zheng:0,
      formant_val:1.1,
      myintegral:0,
      instrumentals:0,
      reverb:0,
      root_path:'',
      fileList:[],
      choose_v_item:{},//临时选中弹窗的声音
      selectVoice:{},//最终选定的声音
      pause_action:'',//弹窗的任何操作都要传给子组件，并且关闭当前播放的声音
      selectedItem:0,
      // uploadUrl: "http://www.music.com/api/musicfyUpload",
      // uploadUrl: "https://ht.7keymusic.com/api/musicfyUpload",
      uploadUrl: process.env.VUE_APP_UPLOAD_MUSICFY_URL,
      voiceReq:{},
      voiceTotal:0,
      page:1,
      limit:20,
      voiceData:{},
      tableData:[],
      dialogVoice: false,
      button_unable:false,
      show: false,//控制播放图标显示 false 不显示  true 显示
    }
  },
  computed: {
    backgroundImage() {
      return {
        backgroundImage: `url(${this.currentBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center', // 让背景图片居中显示
        backgroundRepeat: 'no-repeat', // 避免背景图片重复
      };
    },
  },
  created() {
    this.musicfyGetVoicefunc(); //获取声音模板
    this.musicfyListfunc(); //获取右侧生成列表/
    this.myintegral = JSON.parse(localStorage.getItem('myInfo')).integral
  },
  updated() {

  },
  methods: {
    // 滑块显示
    formatTooltip(val) {
      this.pitch_val = val;
      if(val > 0){
        this.pitch_val_fu = '-0';
        this.pitch_val_zheng = '+'+val;
      }else{
        this.pitch_val_zheng = '+0';
        this.pitch_val_fu = val;
      }
    },

    // 鼠标移入事件
    mouseover() {
      this.show = true
    },
    // 鼠标移出事件
    mouseout() {
      this.show = false
    },

    // 播放
    play() {
      // console.log('播放:', this.obj)
      this.$refs.audio.play();
    },
    // 暂停
    pause() {
      this.$refs.audio.pause();
    },
    instrumentalsFunc(){
      if(this.instrumentals == 1){
        this.instrumentals = 0;
      }else{
        this.instrumentals = 1;
      }
    },
    reberbFunc(){
      if(this.reverb == 1){
        this.reverb = 0;
      }else{
        this.reverb = 1;
      }
    },
    chooseVoice(item){
      this.choose_v_item = item;
    },
    //弹窗选择分类
    selectItem(index,type) {
      if(this.selectedItem == index){
        this.selectedItem = 0;
      }else{
        this.selectedItem = index; // 设置选中的项
      }
      if(this.selectedItem == 0){
        type = 'voices';
      }
      this.eldia_type = type
      this.pause_action = type;
      this.musicfyGetVoicefunc(type);
    },
    //文件上传
    beforeAvatarUpload(file){
      console.log(this.root_path.length)
      if(this.root_path.length > 1){
        this.$message.error('只能转换一个音频');
        this.fileList = []
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传音频大小不能超过 10MB!');
        this.fileList = []
        return
      }
      const isAudio = file.type === 'audio/mpeg';
      const isAudiowav = file.type === 'audio/wav';

      if (!isAudio && !isAudiowav) {
        this.$message.error('请上传指定格式音频');
        this.fileList = []
        return;
      }
    },
    handleAvatarSuccess(res, file) {
      this.fileList =[{'name': file.name, 'url': res.data.path}];
      this.root_path = res.data.path
    },
    handleRemove(file, fileList) {
      this.root_path = ''
    },


    // 翻页
    handleCurrentChange(val) {
      this.page = val
      this.pause_action = String(val);
      this.musicfyGetVoicefunc(this.eldia_type);
    },
    //获取声音模板
    musicfyGetVoicefunc(type='voices'){
      let req = {'type':type,'page':this.page,'limit':this.limit}
      musicfyGetVoice(req).then((res) => {
        this.voiceTotal = res.data.total
        this.voiceData = res.data.data
      }).catch((err) => {
        console.log(err, '声音模板失败');
      });
    },
    //获取右侧结果列表
    musicfyListfunc(){
      musicfyGetList().then((res) => {
        this.tableData = res.data;
      }).catch((err) => {
        console.log(err, 'musicfy列表失败');
      });
    },

    // 立即生成按钮
    createMusicfy(){
      let params = {
        'voice_ids' : this.selectVoice.ids,
        'voice_id' : this.selectVoice.id,
        'root_path' : this.root_path,
        'instrumentals' : this.instrumentals,
        'reverb' : this.reverb,
        'pitch_shift' : this.pitch_val,
        'formant_shift' : this.formant_val,
      }
      this.button_unable = true;
      musicfyCreate(params).then((res) => {
        if(res.status == 1){
          setTimeout(() => {
            this.button_unable = false;
            this.myintegral = this.myintegral - 10
            this.musicfyListfunc(); //获取右侧生成列表/
          }, 5000)
        }else{
          this.button_unable = false;

        }
      }).catch((err) => {
        console.log(err, '生成失败');
        this.button_unable = false;
      });
    },

    //弹窗操作=======================================
    chooseClosedia(){
      this.dialogVoice = false
      if(!this.choose_v_item.thumbnail){
        this.choose_v_item.thumbnail = 'https://tiji2021.oss-cn-beijing.aliyuncs.com/musicfy/system/7KEYMUSIC_LOGO.jpg';
      }
      this.selectVoice = this.choose_v_item
      this.currentBackground = this.selectVoice.thumbnail
    },
    handleOpen(done) {
      this.pause_action = 'open';
    },
    handleClose(done) {
      this.pause_action = 'close';
    }
  },
}
</script>

<style scoped  lang='less'>
.fybody {
  display: flex;
  flex-direction: row;
  width: 100%;
  .fybody_left {
    display: flex;
    width: 55%;
    flex-direction: column;
    color: @headerSelectTextColor  !important;
  }
  .fybody_right {
    margin-left: 15px;
    display: flex;
    width: 45%;
    flex-direction: column;
  }
}
//============ 右 侧 列 表 ==================
.right_list {
  display: flex;
  flex-direction: column;
  width:100%;
  height: 550px;
  overflow-x: hidden;
  margin-top: 20px;
  border-radius: 10px;
  color: @headerSelectTextColor  !important;
  .right_list_m {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-right: 10px;
    background-color: @recommendMusicianListBGColor;
    border-radius: 10px;
    .m_title {
      display: flex;
      align-items: center;
      font-size: 15px;
      width: 200px;
      padding: 10px 0 10px 10px;
    }
    .m_box {
      display: flex;
      flex:1;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }

  }
}
//============ 左 侧 列 表 ==================
.fy_first {
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  background-color: @recommendMusicianListBGColor;
  padding: 10px 0 10px 10px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
  position: relative;
  .fy_first_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .first_left {
    z-index: 20;
    display: flex;
    align-items: center;
    .first_left_one {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed @headerSelectTextColor ;
      cursor: pointer;
      height: 100px;
      width: 100px;
      border-radius: 0.666667rem;
    }
    .first_left_two {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 90px;
      padding:8px 10px;
      .first_left_two_up {
        display: flex;
        padding:5px 0;
        font-size: 30px;
        line-height: 1.1;
        font-weight: 600;
      }
      .first_left_two_down {
        display: flex;
        padding:5px 0;
        font-size: 16px;
      }
    }
  }
  .first_right {
    display: flex;
    width: 200px;
    .right_img {
      max-width: 100%;
      position: absolute;
      right: 0px;
      bottom: 0;
      z-index: 30;
      height: 133%;
      width: auto;
      display: block;
      color: transparent;
    }
    .right_img_c {
      max-width: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 30;
      height: 100%;
      width: auto;
      display: block;
      color: transparent;
      border-radius: 10px;
    }


  }
}
.fy_second {
  display: flex;
  margin:10px 0;
  border-radius: 15px;
  padding: 10px;
  background-color: @recommendMusicianListBGColor;
  .sec_lef {
    display: flex;
    flex: 1;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 15px;
    border: 2px dashed @headerSelectTextColor  ;
    padding: 10px;
    .sec_lef_up {
      display: flex;
      flex: 1;
      border-bottom: 2px solid @headerSelectTextColor;
      .sec_lef_up_le {
        display: flex;
        flex-direction: column;
        flex: 3;
        padding: 10px;
        .sec_lef_up_le_da {
          flex: 1;
          font-size: 26px;
          font-weight: 500;
          padding: 5px;

        }
        .sec_lef_up_le_xiao {
          flex: 1;
          font-size: 17px;
          font-weight: 300;
          padding: 5px;

        }
      }
      .sec_lef_up_ri {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
    }
    .sec_lef_down {
      cursor: pointer;
      display: flex;
      flex: 1;
      padding: 10px 10px 0 10px;
      .sec_lef_down_box {
        display: flex;
        align-items: center;
        justify-content: center;
        border:2px solid @headerSelectTextColor;
        border-radius: 10px;
        width: 50px;
        height: 50px;
      }
    }
  }


}
.fy_third {
  display: flex;
  height: 100px;
  margin:10px 0;
  .third_le {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: @recommendMusicianListBGColor;
    border-radius: 15px;
    border: 2px solid @headerSelectTextColor;
    cursor: pointer;
    .third_le_nei {
      display: flex;
    }
  }

  .third_le_selected {
    border: 2px solid #e36f84;
    color: #e36f84;
  }
  .third_ri {
    margin-left: 20px;
  }

}
.fy_fourth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  margin:10px 0;
  border-radius: 15px;
  width: 100%;
  background-color: @recommendMusicianListBGColor;
  .fourth_up {
    display: flex;
    .fourth_up_left {
      display: flex;
      padding: 10px;
      align-items: center;
      font-size: 16px;
      white-space: nowrap;
      width: 120px;
      justify-content: space-between;
      .fourth_up_left_one {
        display: flex;
        align-items: center;
      }
      .fourth_up_left_two {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding-right: 10px;
      }
    }

    .fourth_up_right {
      display: flex;
      padding: 10px 20px 10px 0;
      width: 80%;
      justify-content: space-around;
      .fourth_up_right_one {
        display: flex;
        width: 60px;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }

    }
  }
}
.wode {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  .wodd {
    display: flex;
    font-size: 15px;
    margin: 10px;
  }
}
.fy_fiveth {
  display: flex;
  justify-content: center;
  align-items: center;
  margin:10px 0;
  border-radius: 15px;
  padding: 10px 0 10px 40px;
    .gradient-btn {
      background-image: linear-gradient(to right, #e6717d 0%, #b656f0 100%);
      border: none;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 20px;
      width: 300px;
      margin: 4px 2px;
      cursor: pointer;
      transition: background-image 0.5s ease;
    }
    .disabled_butt {
      background-color: #00BCA4;
    }
    .gradient-btn:hover {
      background-image: linear-gradient(to right, #b656f0 0%, #e6717d 100%);
    }

}
//=================== 选 择 声 音 弹 窗 ====================
.voictype {
  display: flex;
  align-items: center;
  justify-content: center;
  .voictype_one {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex: 1;
    height: 50px;
    margin: 0px 10px 10px 10px;
    border-radius: 10px;
    background:linear-gradient(45deg, #8551af, #2b3452);
    opacity: 0.6;
    color: rgba(232, 227, 227, 1);

  }
  /* 选中时的样式 */
  .selected {
    background-color: #4CAF50;
    opacity: 1;
    color: white;
  }

}
.dialog_voice {
  display: flex;
  flex-wrap:wrap;
  justify-content: center;   /* 水平居中 */
  align-items: center;       /* 垂直居中 */

  .dialog_voice_box{
    display: flex;
    border-radius: 10px;
    margin: 5px;
    width: 230px;              /* 容器宽度 */
    height: 230px;             /* 容器高度 */
    overflow: hidden;          /* 隐藏超出部分 */
    border: 1px solid #ddd;    /* 可选，边框用于可视化 */
    position: relative;

    img {
      width: 100%;        /* 图片宽度占满容器 */
      height: 100%;       /* 图片高度占满容器 */
      object-fit: cover;  /* 裁剪图片以覆盖整个容器 */
      object-position: center; /* 从中心裁剪图片 */
    }
    .dialog_info {
      position:absolute;
      bottom:0;
      width: 100%;
      height: 80px;
      //background-color: hsla(0,0%,100%,.2);
      background: rgba(169, 173, 175, 0.31);
      backdrop-filter: blur(44px); //模糊玻璃效果
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 10px;
      .dialog_info_left {
        display: flex;
        width: 80px;
        height: 100%;
      }
      .dialog_info_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        height: 100%;
        padding: 0px 10px;
        .dialog_info_right_up {
          font-weight: 700;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1.5;
          span {
            width: 100%; // (一定要加宽度）***************************
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; // 溢出用省略号
            -webkit-line-clamp: 1; //控制显示的行数
            -webkit-box-orient: vertical; // 从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式)
            display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
          }

        }
        .dialog_info_right_down {
          display: flex;
          font-size: 13px;
        }




      }
    }
  }
  .choose_vi_css {
    border:2px solid #794DA24C;
    box-shadow: 0px 0px 10px 10px rgba(121, 77, 162, 0.3);
    opacity: 0.8;
  }
}
/*分页*/
.pagecss {
  display: flex;
  align-items: center;
  justify-content: center;
}


</style>