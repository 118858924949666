<template>
  <div class="main">
    <div class="top" style="position: relative;" @mouseover="mouseover()" @mouseleave="mouseout()">
      <!-- 播放图标 开始 -->
      <audio ref="audio" :src="obj.audioPreviewUrl" @play="play()" @pause="pause()"></audio>
      <div class="play-wrap" v-if="show == true">
        <div class="play" @click="pause()" v-if="obj.playStatus">
          <Icon name="icon-7key-music24gf-pause2" class="icon-play" color="#3370FF"/>
        </div>
        <div class="play" @click="play()" v-else>
          <Icon name="icon-7key-musicplayfill" class="icon-play" color="#3370FF"/>
        </div>
      </div>
      <!-- 播放图标 结束 -->
    </div>
  </div>
</template>

<script>
export default {
  name: "MusicfyIcon",
  props: {
    obj: {
      type: Object,
      default() {
        return {}
      }
    },
    pause_action: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: true,//控制播放图标显示 false 不显示  true 显示
      themeStyle: '',// 主题 default默认主题 dark 深色主题

      audio: null, // 保存Audio实例
      isPlaying: false, // 播放状态
      duration: 0, // 音频总时长
      currentTime: 0, // 当前播放时间
    }
  },
  watch: {
    "$store.state.musicfyInfo": {handler(newValue, oldValue) {
        // console.log("音乐信息变化:", newValue);
        if (newValue.ids !== this.obj.ids && this.obj.playStatus) {
          let t = setTimeout(() => {
            this.pause()
            clearTimeout(t)
          }, 100)
        }
      },
      deep: true
    },
    pause_action() {
        this.pause();
    }
  },
  mounted() {
  },
  created() {
    // this.themeStyle = localStorage.getItem("theme")
  },
  methods: {
    // 播放
    play() {
      this.$set(this.obj, 'playStatus', true)
      // console.log('播放:', this.obj)
      this.$refs.audio.play();
      this.$store.state.musicfyInfo = this.obj;
    },
    // 暂停
    pause() {
      this.$set(this.obj, 'playStatus', false)
      // console.log('暂停:', this.obj)
      this.$refs.audio.pause();
    },
    // 鼠标移入事件
    mouseover() {
      this.show = true
    },
    // 鼠标移出事件
    mouseout() {
      this.show = true
    },
  },
  beforeDestroy() {
    // 在组件销毁时移除所有事件监听，并暂停音频
    this.$refs.audio.pause();
  },
}
</script>

<style lang="less" scoped>
.main {
  // width: 220px;
  //width: 100%;
  //height: 296px;
  // height: 356px;
  
  // height: auto;
  //background: @recommendMusicianListBGColor;
  color: @recommendMusicianListTextColor;
  border-radius: 6px;
  //cursor: pointer;

  .top {
    // background-image: url('../../assets/images/recommend/song-bg.jpg');
    // background-size: 100% 100%;
    // width: 220px;
    width: 80px;
    height: 80px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    .play-wrap {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      //background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      z-index: 33;
      display: flex;
      align-items: center;
      justify-content: center;

      .play {
        color: #3370FF;
        font-size: 40px;
        width: 50px;
        height: 50px;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.8);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: RGBA(169, 173, 175, 0.3);
        z-index: 33;
		.icon-play{
			margin-top: -6px;
		}
      }
    }


  }

  .bottom {
    display: flex;
    flex-direction: column;
	// padding-bottom: 26px;

    .title-time {
      display: flex;
      justify-content: space-between;
      margin-top: 6px;

      .title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 500;
        width: 70%; // (一定要加宽度）
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; // 溢出用省略号
        // white-space: nowrap; //溢出不换行
        -webkit-line-clamp: 1; //控制显示的行数
        -webkit-box-orient: vertical; // 从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式)
        display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
      }

      .time {
        margin-right: 12px;
        color: @recommendMusicianListTitleColor;
        font-size: 12px;
      }
    }

    .sing-collect {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 36px;
      align-items: center;
      margin-left: 16px;
      margin-top: 10px;

      .sing {
        margin-right: 12px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        .sing_txt {
          display: flex;
          font-size: 12px;
          margin-right: 2px;
          cursor: pointer;
        }
        .sing-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
        }

      }
      .collect {
        margin-right: 12px;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-content: center;

        .down_txt {
          display: flex;
          font-size: 12px;
          margin-right: 2px;
          cursor: pointer;
        }
        .down-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          cursor: pointer;
        }
        img {
          width: 18px;
          height: 18px;

        }
      }
    }
  }
}
</style>
