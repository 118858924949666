<template>
  <div class="main">
    <!--    第一个播放图标  -->
    <div class="top" v-show="obj.local_combined">
      <audio ref="audio1" :src="obj.local_combined"></audio>
      <div class="play-wrap" v-if="show == true">
        <div class="play" @click="pause(1)" v-if="isPlaying1">
          <Icon name="icon-7key-music24gf-pause2" class="icon-play" color="#3370FF"/>
        </div>
        <div class="play" @click="play(1)" v-else>
          <Icon name="icon-7key-musicplayfill" class="icon-play" color="#3370FF"/>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom_lef">combined</div>
        <div class="bottom_right" @click="xiazai(obj.local_combined,'combined')">下载</div>
      </div>
    </div>

    <!--    第二个播放图标  -->
    <div class="top" v-show="obj.local_instrumentals">
      <audio ref="audio2" :src="obj.local_instrumentals"></audio>
      <div class="play-wrap" v-if="show == true">
        <div class="play" @click="pause(2)" v-if="isPlaying2">
          <Icon name="icon-7key-music24gf-pause2" class="icon-play" color="#3370FF"/>
        </div>
        <div class="play" @click="play(2)" v-else>
          <Icon name="icon-7key-musicplayfill" class="icon-play" color="#3370FF"/>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom_lef">instrumental</div>
        <div class="bottom_right" @click="xiazai(obj.local_instrumentals,'instrumentals')">下载</div>
      </div>
    </div>
    <!--    第三个播放图标  -->
    <div class="top" v-show="obj.local_vocals">
      <audio ref="audio3" :src="obj.local_vocals"></audio>
      <div class="play-wrap" v-if="show == true">
        <div class="play" @click="pause(3)" v-if="isPlaying3">
          <Icon name="icon-7key-music24gf-pause2" class="icon-play" color="#3370FF"/>
        </div>
        <div class="play" @click="play(3)" v-else>
          <Icon name="icon-7key-musicplayfill" class="icon-play" color="#3370FF"/>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom_lef">vocal</div>
        <div class="bottom_right" @click="xiazai(obj.local_vocals,'vocals')">下载</div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "MusicfyListIcon",
  props: {
    obj: {
      type: Object,
      default() {
        return {}
      }
    },
    pause_action: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: true,//控制播放图标显示 false 不显示  true 显示
      themeStyle: '',// 主题 default默认主题 dark 深色主题
      audio: null, // 保存Audio实例
      isPlaying1: false, // 播放状态
      isPlaying2: false, // 播放状态
      isPlaying3: false, // 播放状态
      duration: 0, // 音频总时长
      currentTime: 0, // 当前播放时间
    }
  },
  watch: {
    "$store.state.musicfyListInfo": {handler(newValue, oldValue) {
        // console.log("音乐信息变化:", newValue.local_vocals);
        if (newValue.id !== this.obj.id) {
          let t = setTimeout(() => {
            this.pause()
            clearTimeout(t)
          }, 100)
        }
      },
      deep: true
    },
    pause_action() {
        // this.pause();
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    xiazai(url,title){
      const a = document.createElement('a')
      a.href = url
      a.download = title // 下载后文件名
      a.style.display = 'none'
      a.target = '_blank'
      document.body.appendChild(a)
      a.click() // 点击下载
      document.body.removeChild(a) // 下载完成移除元素
    },
    //全部暂停，暂停初始化
    allZanting(){
      this.isPlaying1 = false
      this.isPlaying2 = false
      this.isPlaying3 = false
      this.$refs.audio1.pause();
      this.$refs.audio2.pause();
      this.$refs.audio3.pause();
    },
    // 播放
    play(e) {
      this.allZanting()
      if(e == 1) this.isPlaying1= true;
      if(e == 2) this.isPlaying2= true;
      if(e == 3) this.isPlaying3= true;
      console.log(this.$refs['audio'+ e].src)
      this.$refs['audio'+ e].play();
      this.$store.state.musicfyListInfo = this.obj;
    },
    // 暂停
    pause(e) {
      this.allZanting()
      // this.$refs['audio'+ e].pause();
    },
    // 鼠标移入事件
    mouseover() {
      this.show = true
    },
    // 鼠标移出事件
    mouseout() {
      this.show = true
    },
  },
  beforeDestroy() {
    // 在组件销毁时移除所有事件监听，并暂停音频
    this.$refs.audio1.pause();
    this.$refs.audio2.pause();
    this.$refs.audio3.pause();
  },
}
</script>

<style lang="less" scoped>
.main {
  // width: 220px;
  width: 100%;
  //height: 296px;
  // height: 356px;
  
  // height: auto;
  //background: @recommendMusicianListBGColor;
  color: @recommendMusicianListTextColor;
  border-radius: 6px;
  //cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border: 2px solid @headerSelectTextColor ;
    padding: 10px 5px 0 5px;
    border-radius: 10px;

    .play-wrap {
      background-image: url('../../assets/images/recommend/avator.png');
      width: 100px;
      height: 100px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .play {
        cursor: pointer;
        color: #3370FF;
        font-size: 40px;
        width: 50px;
        height: 50px;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.8);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: RGBA(169, 173, 175, 0.3);
        z-index: 33;
        .icon-play{
          margin-top: -6px;
        }
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 30px;
      .bottom_lef {
        display: flex;
        font-size: 11px;
      }
      .bottom_right {
        cursor: pointer;
        display: flex;
        font-size: 13px;
      }
    }


  }

}
</style>
